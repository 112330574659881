import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { colors } from "../../styles/colors"
import { layoutStyles } from "../../styles/layoutStyles"
import formularIcon from "../../assets/images/icon/202009_IHK_Antwortformular_Pfeil.png"
import { CustomCheckbox } from "../customInputs/CustomCheckbox"
import { HookIcon } from "../helper/HookIcon"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const BlueBackgroundContainer = styled.div`
  background-color: ${colors.ihk_lightblue};
`
const InnerConsultingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${layoutStyles.contentWidth};
  min-height: 120px;
  padding: ${layoutStyles.contentPadding};
  margin: 0 auto;
  font-size: 2.25rem;
  font-family: "Asap Condensed", sans-serif;
  color: ${colors.ihk_darkblue};
  cursor: pointer;
`
const InnerConsultingContainerWithMargin = styled(InnerConsultingContainer)`
  @media only screen and (max-width: 650px) {
    margin: 1rem auto;
    font-size: 2rem;
  }
`

const ConsultingForm = styled.div`
  max-height: ${props => (props.isFormOpen ? "1000px" : "0")};
  transform: translate(0px);
  overflow: hidden;
  transition: max-height 0.25s ease-in;
  max-width: ${layoutStyles.contentWidth};
  padding: ${layoutStyles.contentPadding};
  margin: 0 auto;
`
const FormInputContainer = styled.label`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  font-family: "Asap Condensed", sans-serif;
`

const StFormInput = styled.input`
  padding: 0.75rem;
  margin-top: 0.35rem;
  border: ${props => (!props.valid ? "1px solid red" : "none")};
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
`

const FormInputMessage = styled.textarea`
  padding: 0.75rem;
  margin-top: 0.25rem;
  border: none;
  height: 150px;
  resize: none;
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
`

const StFormButton = styled.button`
  background-color: ${colors.ihk_darkblue};
  color: white;
  font-weight: bold;
  padding: 0.75rem 3rem;
  margin: 2rem 0;
  border: none;
  outline: none;
  cursor: pointer;
`
const StFormButtonDisabled = styled(StFormButton)`
  display: flex;
  align-items: center;
  cursor: auto;
  background-color: ${colors.ihk_lightblue};
  border: 1px solid ${colors.ihk_darkblue};
  color: ${colors.ihk_darkblue};
`

const StHookIconBlue = styled(HookIcon)`
  stroke: ${colors.ihk_darkblue};
  margin-right: 1rem;
`

const PrivacyContainer = styled.label`
  display: flex;
  width: 82%;
  margin-bottom: 3rem;
`

const DataPrivacyText = styled.p`
  margin-top: 0 !important;
`
const FormularIcon = styled.img`
  width: 25px;
  height: auto;
  margin-right: 1rem;
`

export const IndividualConsulting = ({ isFormOpen, setIsFormOpen }) => {
  const [forceFormClosed, setForceFormClosed] = useState(false)
  const [privacyChecked, setPrivacyChecked] = useState(false)
  const [privacyCheckedValid, setPrivacyCheckedValid] = useState(true)
  const [name, setName] = useState("")
  const [nameValid, setNameValid] = useState(true)
  const [mail, setMail] = useState("")
  const [mailValid, setMailValid] = useState(true)
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [formHasBeenSent, setFormHasBeenSent] = useState(false)

  const checkHash = useCallback(
    event => {
      const hash = window.location.hash.toString().replace("#", "")

      if (hash === "beratung" && !forceFormClosed) {
        setIsFormOpen(true)
      } else if (hash === "beratung" && forceFormClosed) {
        setIsFormOpen(false)
      }
    },
    [forceFormClosed]
  )

  useEffect(() => {
    window.addEventListener("scroll", checkHash)

    return () => window.removeEventListener("scroll", checkHash)
  }, [checkHash])

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const sendEtrackerFormularSubmissionEvent = event => {
    if (
      typeof window !== "undefined" &&
      window &&
      window._etracker &&
      window._etracker.sendEvent &&
      window.et_UserDefinedEvent
    ) {
      const _etracker = window._etracker
      _etracker.sendEvent(
        new window.et_UserDefinedEvent(
          "Beratungs-Formular-Gesendet",
          "Formulare",
          "Absenden"
        )
      )
    }
  }

  const handleFormSubmit = event => {
    event.preventDefault()

    let nameValid = true
    let mailValid = true
    let privacyValid = true

    if (name.length < 1) {
      nameValid = false
      setNameValid(false)
    } else {
      setNameValid(true)
    }

    if (mail.length < 3) {
      setMailValid(false)
      mailValid = false
    } else {
      setMailValid(true)
    }

    if (!privacyChecked) {
      setPrivacyCheckedValid(false)
      privacyValid = false
    } else {
      setPrivacyCheckedValid(true)
    }

    if (!nameValid || !mailValid || !privacyValid) {
      return
    }

    if (nameValid && mailValid && privacyValid) {
      const form = event.target

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          name,
          "e-mail": mail,
          "Mobil oder Telefon": phone,
          Nachricht: message,
        }),
      })
        .then(() => {
          setName("")
          setMail("")
          setPhone("")
          setMessage("")
          setPrivacyChecked(false)
          setFormHasBeenSent(true)
          sendEtrackerFormularSubmissionEvent(event)
        })
        .catch(error =>
          alert(
            "Leider ist beim Senden Ihrer Anfrage ein Fehler aufgetreten. Bitte versuchen Sie es erneut. Sollte der Fehler weiterhin bestehen, kontaktieren Sie uns bitte."
          )
        )
    }
  }

  return (
    <Container id={"beratung"}>
      <BlueBackgroundContainer>
        <InnerConsultingContainerWithMargin
          onClick={() => {
            setForceFormClosed(!forceFormClosed)
            setIsFormOpen(!isFormOpen)
          }}
        >
          <FormularIcon src={formularIcon} alt={"Formular öffnen/schließen"} />
          Interesse geweckt? Jetzt individuelle Beratung anfordern!
        </InnerConsultingContainerWithMargin>
        <ConsultingForm isFormOpen={isFormOpen}>
          <form
            onSubmit={handleFormSubmit}
            name={"Individuelle Beratung"}
            method={"POST"}
            data-netlify="true"
          >
            <input type="hidden" name="Individuelle Beratung" value="contact" />
            <FormInputContainer>
              Name
              <StFormInput
                onChange={e => {
                  setName(e.target.value)
                }}
                value={name}
                valid={nameValid}
                type="text"
                name="name"
                id={"name"}
                placeholder={"Pflichtangabe"}
              />
            </FormInputContainer>
            <FormInputContainer>
              E-Mail
              <StFormInput
                onChange={e => {
                  setMail(e.target.value)
                }}
                value={mail}
                valid={mailValid}
                type="email"
                name="e-mail"
                id={"mail"}
                placeholder={"Pflichtangabe"}
              />
            </FormInputContainer>
            <FormInputContainer>
              Mobil oder Telefon
              <StFormInput
                onChange={e => {
                  setPhone(e.target.value)
                }}
                value={phone}
                valid={true}
                type="tel"
                name="Mobil oder Telefon"
                id={"phone"}
                placeholder={"optional"}
              />
            </FormInputContainer>
            <FormInputContainer>
              Nachricht
              <FormInputMessage
                onChange={e => {
                  setMessage(e.target.value)
                }}
                value={message}
                valid={true}
                type="text"
                name="Nachricht"
                id={"message"}
                placeholder={"optional"}
              />
            </FormInputContainer>
            {!formHasBeenSent ? (
              <StFormButton type={"submit"}>ANFRAGE ABSENDEN</StFormButton>
            ) : (
              <StFormButtonDisabled disabled>
                <StHookIconBlue />
                ANFRAGE WURDE GESENDET
              </StFormButtonDisabled>
            )}
            <PrivacyContainer>
              <CustomCheckbox
                checked={privacyChecked}
                onChange={e => {
                  setPrivacyChecked(e.target.checked)
                  setPrivacyCheckedValid(true)
                }}
                valid={privacyCheckedValid}
              />
              <DataPrivacyText onClick={e => e.preventDefault()}>
                Mit dem Absenden Ihrer Daten erklären Sie sich damit
                einverstanden, dass wir Ihre Daten ausschließlich zu
                Kontaktzwecken nutzen und speichern. Die Daten können auf Wunsch
                jederzeit gelöscht, widerrufen, eingesehen, transferiert und
                berichtigt werden. Nähere Informationen dazu entnehmen Sie bitte
                unserer Datenschutzerklärung.
              </DataPrivacyText>
            </PrivacyContainer>
          </form>
        </ConsultingForm>
      </BlueBackgroundContainer>
    </Container>
  )
}
