import React from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"

const SubjectContainer = styled.div`
  margin: 3rem 0 3rem 0;
  width: 250px;
  height: 250px;
  opacity: ${props => (props.isHovered ? "1" : "0")};
  transition: opacity 0.3s;
  z-index: ${props => (props.isHovered ? "99" : "-1")};
  box-shadow: 0 0 5px 5px rgba(44, 43, 43, 0.2);
  background-color: white;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const Container = styled(SubjectContainer)`
  width: 240px;
  height: 150px;
  border: 4px solid ${colors.ihk_green};
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 145px;
  padding: 0.5rem;
  font-family: "Asap Condensed", sans-serif;
`

const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  border-bottom: 2px solid ${colors.ihk_green};
  font-family: "Asap Condensed", sans-serif;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0.5rem 0 0 0.5rem;
`

const StBulletpoint = styled.span`
  margin-bottom: 0.5rem;
`

const StTitle = styled.span`
  margin: 0.5rem;
`

export const SubjectCard = ({
  title,
  bulletpoints,
  id,
  isHovered,
  className,
}) => {
  return (
    <Container id={id} className={className} isHovered={isHovered}>
      <InnerContainer id={id}>
        <Headline>
          <StTitle>{title}</StTitle>
        </Headline>
        <ContentContainer>
          {bulletpoints.map((element, index) => (
            <StBulletpoint key={`${index}-${element}`}>
              > {element}
            </StBulletpoint>
          ))}
        </ContentContainer>
      </InnerContainer>
    </Container>
  )
}
