import React, { useState } from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import { Factory } from "../components/Factory"
import { BestPractice } from "../components/BestPractice"
import { IndividualConsulting } from "../components/forms/IndividualConsulting"
import SEO from "../components/seo"
import { SubjectList } from "../components/SubjectList"
import { MeasureForm } from "../components/forms/MeasureForm"
import { PartnerLogos } from "../components/PartnerLogos"

const IndexPage = () => {
  const [lightningOpen, setLightningOpen] = useState(false)
  const [biodiversityOpen, setBiodiversityOpen] = useState(false)
  const [airpreasureOpen, setAirPreasureOpen] = useState(false)
  const [energyManagementOpen, setEnergyManagementOpen] = useState(false)
  const [energySupplyOpen, setEnergySupplyOpen] = useState(false)
  const [buildingMaterialOpen, setBuildingMaterialOpen] = useState(false)
  const [machineOptimizingOpen, setMachineOptimizingOpen] = useState(false)
  const [motivationOpen, setMotivationOpen] = useState(false)
  const [mobilityOpen, setMobilityOpen] = useState(false)
  const [recyclingOpen, setRecyclingOpen] = useState(false)
  const [temperatureTechniquesOpen, setTemperatureTechniquesOpen] = useState(
    false
  )
  const [isMeasureFormOpen, setIsMeasureFormOpen] = useState(true)
  const [isContactFormOpen, setIsContactFormOpen] = useState(false)

  const toggleOpenSubjects = (toggleSubjectFunction, currentState) => {
    if (
      typeof toggleSubjectFunction === "function" &&
      typeof currentState === "boolean"
    ) {
      setLightningOpen(false)
      setBiodiversityOpen(false)
      setAirPreasureOpen(false)
      setEnergyManagementOpen(false)
      setEnergySupplyOpen(false)
      setBuildingMaterialOpen(false)
      setMachineOptimizingOpen(false)
      setMotivationOpen(false)
      setMobilityOpen(false)
      setRecyclingOpen(false)
      setTemperatureTechniquesOpen(false)

      toggleSubjectFunction(!currentState)
    }
  }

  return (
    <Layout setIsContactFormOpen={setIsContactFormOpen}>
      <SEO
        title="Home"
        keywords={[
          `IHK`,
          `Energeffizienznetzwerk`,
          `ETA`,
          "ETA-Plus",
          "IHK - Energeffizienznetzwerk",
        ]}
      />
      <Helmet>
        <meta
          name="google-site-verification"
          content="N27U1F_kF4VPZAHqoy02Pt-EezjPoa6MGOlr8c8ORSg"
        />
      </Helmet>
      <Factory
        lightningOpen={lightningOpen}
        biodiversityOpen={biodiversityOpen}
        airpreasureOpen={airpreasureOpen}
        energyManagementOpen={energyManagementOpen}
        energySupplyOpen={energySupplyOpen}
        buildingMaterialOpen={buildingMaterialOpen}
        machineOptimizingOpen={machineOptimizingOpen}
        motivationOpen={motivationOpen}
        mobilityOpen={mobilityOpen}
        recyclingOpen={recyclingOpen}
        temperatureTechniquesOpen={temperatureTechniquesOpen}
        setLightningOpen={setLightningOpen}
        toggleOpenSubjects={toggleOpenSubjects}
        setBiodiversityOpen={setBiodiversityOpen}
        setAirPreasureOpen={setAirPreasureOpen}
        setEnergyManagementOpen={setEnergyManagementOpen}
        setEnergySupplyOpen={setEnergySupplyOpen}
        setBuildingMaterialOpen={setBuildingMaterialOpen}
        setMachineOptimizingOpen={setMachineOptimizingOpen}
        setMotivationOpen={setMotivationOpen}
        setMobilityOpen={setMobilityOpen}
        setRecyclingOpen={setRecyclingOpen}
        setTemperatureTechniquesOpen={setTemperatureTechniquesOpen}
      />
      <BestPractice />
      <SubjectList
        onClickEnterProject={() => {
          setIsMeasureFormOpen(true)
        }}
        lightningOpen={lightningOpen}
        biodiversityOpen={biodiversityOpen}
        airpreasureOpen={airpreasureOpen}
        energyManagementOpen={energyManagementOpen}
        energySupplyOpen={energySupplyOpen}
        buildingMaterialOpen={buildingMaterialOpen}
        machineOptimizingOpen={machineOptimizingOpen}
        motivationOpen={motivationOpen}
        mobilityOpen={mobilityOpen}
        recyclingOpen={recyclingOpen}
        temperatureTechniquesOpen={temperatureTechniquesOpen}
        setLightningOpen={setLightningOpen}
        setBiodiversityOpen={setBiodiversityOpen}
        setAirPreasureOpen={setAirPreasureOpen}
        setEnergyManagementOpen={setEnergyManagementOpen}
        setEnergySupplyOpen={setEnergySupplyOpen}
        setBuildingMaterialOpen={setBuildingMaterialOpen}
        setMachineOptimizingOpen={setMachineOptimizingOpen}
        setMotivationOpen={setMotivationOpen}
        setMobilityOpen={setMobilityOpen}
        setRecyclingOpen={setRecyclingOpen}
        setTemperatureTechniquesOpen={setTemperatureTechniquesOpen}
        toggleOpenSubjects={toggleOpenSubjects}
      />
      <MeasureForm
        isFormOpen={isMeasureFormOpen}
        setIsFormOpen={setIsMeasureFormOpen}
      />
      <PartnerLogos />

      <IndividualConsulting
        isFormOpen={isContactFormOpen}
        setIsFormOpen={setIsContactFormOpen}
      />
    </Layout>
  )
}

export default IndexPage
