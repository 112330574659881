import React, { useCallback, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CompanyCard } from "./CompanyCard"
import { sendEtrackerSubjectClickEvent } from "../utils/etracker"
import styled from "styled-components"
import { colors } from "../styles/colors"
import Img from "gatsby-image"
import { layoutStyles } from "../styles/layoutStyles"

const StHeadlineContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid ${colors.ihk_green};
  background-color: ${props =>
    !props.isOpen ? colors.ihk_lightgreen : "white"};
  margin-bottom: 2rem;
  cursor: pointer;
`
const StHeadlineImage = styled(Img)`
  width: 40px;
  height: auto;
  margin-right: 1rem;
  border-right: 2px solid ${colors.ihk_green};
`
const StHeadlineText = styled.span`
  font-family: "Asap Condensed", sans-serif;
`

const SubjectContentContainer = styled.div`
  width: 100%;
  max-height: ${props => (props.isOpen ? "2000px" : 0)};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
`

const NoCompaniesPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem;
  align-items: center;
  justify-content: center;
`

const StLink = styled.a`
  background-color: ${colors.ihk_green};
  color: ${colors.ihk_darkblue};
  padding: 0.3rem;
  margin: 1rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
`

const StSubjectListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem auto;
  align-items: center;
  max-width: ${layoutStyles.contentWidth};
  padding: ${layoutStyles.contentPadding};
`

export const SubjectList = ({
  onClickEnterProject,
  toggleOpenSubjects,
  setLightningOpen,
  setBiodiversityOpen,
  setAirPreasureOpen,
  setEnergyManagementOpen,
  setEnergySupplyOpen,
  setBuildingMaterialOpen,
  setMachineOptimizingOpen,
  setMotivationOpen,
  setMobilityOpen,
  setRecyclingOpen,
  setTemperatureTechniquesOpen,
  lightningOpen,
  biodiversityOpen,
  airpreasureOpen,
  energyManagementOpen,
  energySupplyOpen,
  buildingMaterialOpen,
  machineOptimizingOpen,
  motivationOpen,
  mobilityOpen,
  recyclingOpen,
  temperatureTechniquesOpen,
}) => {
  const [biodiversityCompanies, setBiodiversityCompanies] = useState([])
  const [lightningCompanies, setLightningCompanies] = useState([])
  const [airPreasureCompanies, setAirPreasureCompanies] = useState([])
  const [energyManagementCompanies, setEnergyManagementCompanies] = useState([])
  const [energySupplyCompanies, setEnergySupplyCompanies] = useState([])
  const [buildingMaterialCompanies, setBuildingMaterialCompanies] = useState([])
  const [machineOptimizingCompanies, setMachineOptimizingCompanies] = useState(
    []
  )
  const [motivationCompanies, setMotivationCompanies] = useState([])
  const [mobilityCompanies, setMobilityCompanies] = useState([])
  const [recyclingCompanies, setRecyclingCompanies] = useState([])
  const [
    temperatureTechniquesCompanies,
    setTemperatureTechniquesCompanies,
  ] = useState([])

  const data = useStaticQuery(graphql`
    {
      allJson {
        group(field: subjects) {
          fieldValue
          edges {
            node {
              id
              title
              companyLogo {
                id
                publicURL
              }
              coSaving
              company
              consultationAndPartner
              energySavingPerYear
              fundingAmount
              fundingProgram
              investmentAmount
              measure
              paybackPeriod
              subjects
              homepageCompanyLink
              homepageConsultationAndPartner
              homepageFundingprogram
              moreEnergyAndProjectPartners
            }
          }
        }
      }
      beleuchtungIcon: file(
        relativePath: { eq: "IHK_Beleuchtung_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      biodiversitaetIcon: file(
        relativePath: { eq: "IHK_Biodiversitaet_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      druckluftIcon: file(
        relativePath: { eq: "IHK_Druckluft_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      energiemanagementIcon: file(
        relativePath: { eq: "IHK_Energiemanagement_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      energieversorgungIcon: file(
        relativePath: { eq: "IHK_Energieversorgung_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      gebaeudeBaumaterialIcon: file(
        relativePath: { eq: "IHK_GebaeudeBaumaterial_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      maschinenOptimierungIcon: file(
        relativePath: {
          eq: "IHK_Maschinenoptimierung_Icon_weiß_ohneRahmen.png"
        }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      motivationIcon: file(
        relativePath: { eq: "IHK_Motivation_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mobilitaetIcon: file(
        relativePath: { eq: "IHK_Mobilitaet_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      recyclingIcon: file(
        relativePath: { eq: "IHK_Recycling_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      waermeUndKaelteIcon: file(
        relativePath: { eq: "IHK_WaermeKaeltetechnik_Icon_weiß_ohneRahmen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const getCompaniesForSubject = useCallback(() => {
    const subjectGroups = data.allJson.group
    subjectGroups.map(element => {
      switch (element.fieldValue.toString()) {
        case "Beleuchtung":
          setLightningCompanies(element.edges)
          break
        case "Biodiversität":
          setBiodiversityCompanies(element.edges)
          break
        case "Druckluft":
          setAirPreasureCompanies(element.edges)
          break
        case "Energiemanagement":
          setEnergyManagementCompanies(element.edges)
          break
        case "Energieversorgung":
          setEnergySupplyCompanies(element.edges)
          break
        case "Gebäude und Baumaterial":
          setBuildingMaterialCompanies(element.edges)
          break
        case "Maschinenoptimierung":
          setMachineOptimizingCompanies(element.edges)
          break
        case "Mitarbeitermotivation":
          setMotivationCompanies(element.edges)
          break
        case "Mobilität":
          setMobilityCompanies(element.edges)
          break
        case "Recycling":
          setRecyclingCompanies(element.edges)
          break
        case "Wärme- und Kältetechnik":
          setTemperatureTechniquesCompanies(element.edges)
          break
        default:
          break
      }
      return null
    })
  }, [data])

  useEffect(() => {
    getCompaniesForSubject()
  }, [getCompaniesForSubject])

  useEffect(() => {
    checkHash()
  }, [])

  const checkHash = () => {
    const hash = window.location.hash.toString().replace("#", "")
    switch (hash) {
      case "beleuchtung":
        toggleOpenSubjects(setLightningOpen, lightningOpen)
        break
      case "biodiversitaet":
        toggleOpenSubjects(setBiodiversityOpen, biodiversityOpen)
        break
      case "druckluft":
        toggleOpenSubjects(setAirPreasureOpen, airpreasureOpen)
        break
      case "energiemanagement":
        toggleOpenSubjects(setEnergyManagementOpen, energyManagementOpen)
        break
      case "energieversorgung":
        toggleOpenSubjects(setEnergySupplyOpen, energySupplyOpen)
        break
      case "material":
        toggleOpenSubjects(setBuildingMaterialOpen, buildingMaterialOpen)
        break
      case "maschinenoptimierung":
        toggleOpenSubjects(setMachineOptimizingOpen, machineOptimizingOpen)
        break
      case "motivation":
        toggleOpenSubjects(setMotivationOpen, motivationOpen)
        break
      case "mobilitaet":
        toggleOpenSubjects(setMobilityOpen, mobilityOpen)
        break
      case "recycling":
        toggleOpenSubjects(setRecyclingOpen, recyclingOpen)
        break
      case "waermekaelte":
        toggleOpenSubjects(
          setTemperatureTechniquesOpen,
          temperatureTechniquesOpen
        )
        break
      default:
        break
    }
  }

  const getJSXforCompaniesOfSubject = companies => {
    if (!companies || companies.length < 1) {
      return (
        <NoCompaniesPlaceholder>
          <span>
            In diesem Bereich freuen wir uns besonders auf Beispiele aus Ihrem
            Unternehmen. <br /> Reichen Sie gerne Ihr Projekt ein.
          </span>

          <StLink
            href={"/#knowhow-teilen"}
            onClick={() => onClickEnterProject()}
          >
            Projekt eintragen
          </StLink>
        </NoCompaniesPlaceholder>
      )
    } else
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: "2rem",
          }}
        >
          {companies.map((node, index) => (
            <CompanyCard key={index} company={node.node} />
          ))}
          <StLink href="/#knowhow-teilen" onClick={() => onClickEnterProject()}>
            Projekt eintragen
          </StLink>
        </div>
      )
  }

  return (
    <StSubjectListContainer>
      <StHeadlineContainer
        isOpen={lightningOpen}
        onClick={event => {
          toggleOpenSubjects(setLightningOpen, lightningOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "BELEUCHTUNG",
            "Click"
          )
        }}
        id={"beleuchtung"}
      >
        <StHeadlineImage
          fixed={data.beleuchtungIcon.childImageSharp.fixed}
          alt={"Beleuchtung"}
          title={"Beleuchtung"}
        />
        <StHeadlineText>BELEUCHTUNG</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={lightningOpen}>
        {getJSXforCompaniesOfSubject(lightningCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={biodiversityOpen}
        onClick={event => {
          toggleOpenSubjects(setBiodiversityOpen, biodiversityOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "BIODIVERSITÄT",
            "Click"
          )
        }}
        id={"biodiversitaet"}
      >
        <StHeadlineImage
          fixed={data.biodiversitaetIcon.childImageSharp.fixed}
          alt={"Biodiversität"}
          title={"Biodiversität"}
        />
        <StHeadlineText>BIODIVERSITÄT</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={biodiversityOpen}>
        {getJSXforCompaniesOfSubject(biodiversityCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={airpreasureOpen}
        onClick={event => {
          toggleOpenSubjects(setAirPreasureOpen, airpreasureOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "DRUCKLUFT",
            "Click"
          )
        }}
        id={"druckluft"}
      >
        <StHeadlineImage
          fixed={data.druckluftIcon.childImageSharp.fixed}
          alt={"Druckluft"}
          title={"Druckluft"}
        />
        <StHeadlineText>DRUCKLUFT</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={airpreasureOpen}>
        {getJSXforCompaniesOfSubject(airPreasureCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={energyManagementOpen}
        onClick={event => {
          toggleOpenSubjects(setEnergyManagementOpen, energyManagementOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "ENERGIEMANAGEMENT",
            "Click"
          )
        }}
        id={"energiemanagement"}
      >
        <StHeadlineImage
          fixed={data.energiemanagementIcon.childImageSharp.fixed}
          alt={"Energiemanagement"}
          title={"Energiemanagement"}
        />
        <StHeadlineText>ENERGIEMANAGEMENT</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={energyManagementOpen}>
        {getJSXforCompaniesOfSubject(energyManagementCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={energySupplyOpen}
        onClick={event => {
          toggleOpenSubjects(setEnergySupplyOpen, energySupplyOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "ENERGIEVERSORGUNG",
            "Click"
          )
        }}
        id={"energieversorgung"}
      >
        <StHeadlineImage
          fixed={data.energieversorgungIcon.childImageSharp.fixed}
          alt={"Energieversorgung"}
          title={"Energieversorgung"}
        />
        <StHeadlineText>ENERGIEVERSORGUNG</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={energySupplyOpen}>
        {getJSXforCompaniesOfSubject(energySupplyCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={buildingMaterialOpen}
        onClick={event => {
          toggleOpenSubjects(setBuildingMaterialOpen, buildingMaterialOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "GEBÄUDE UND BAUMATERIAL",
            "Click"
          )
        }}
        id={"material"}
      >
        <StHeadlineImage
          fixed={data.gebaeudeBaumaterialIcon.childImageSharp.fixed}
          alt={"Gebäude und Baumaterial"}
          title={"Gebäude und Baumaterial"}
        />
        <StHeadlineText>GEBÄUDE UND BAUMATERIAL</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={buildingMaterialOpen}>
        {getJSXforCompaniesOfSubject(buildingMaterialCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={machineOptimizingOpen}
        onClick={event => {
          toggleOpenSubjects(setMachineOptimizingOpen, machineOptimizingOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "MASCHINENOPTIMIERUNG",
            "Click"
          )
        }}
        id={"maschinenoptimierung"}
      >
        <StHeadlineImage
          fixed={data.maschinenOptimierungIcon.childImageSharp.fixed}
          alt={"Maschinenoptimierung"}
          title={"Maschinenoptimierung"}
        />
        <StHeadlineText>MASCHINENOPTIMIERUNG</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={machineOptimizingOpen}>
        {getJSXforCompaniesOfSubject(machineOptimizingCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={motivationOpen}
        onClick={event => {
          toggleOpenSubjects(setMotivationOpen, motivationOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "MITARBEITERMOTIVATION",
            "Click"
          )
        }}
        id={"motivation"}
      >
        <StHeadlineImage
          fixed={data.motivationIcon.childImageSharp.fixed}
          alt={"Motivation"}
          title={"Motivation"}
        />
        <StHeadlineText>MITARBEITERMOTIVATION</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={motivationOpen}>
        {getJSXforCompaniesOfSubject(motivationCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={mobilityOpen}
        onClick={event => {
          toggleOpenSubjects(setMobilityOpen, mobilityOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "MOBILITÄT",
            "Click"
          )
        }}
        id={"mobilitaet"}
      >
        <StHeadlineImage
          fixed={data.mobilitaetIcon.childImageSharp.fixed}
          alt={"Mobilität"}
          title={"Mobilität"}
        />
        <StHeadlineText>MOBILITÄT</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={mobilityOpen}>
        {getJSXforCompaniesOfSubject(mobilityCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={recyclingOpen}
        onClick={event => {
          toggleOpenSubjects(setRecyclingOpen, recyclingOpen)
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "RECYCLING",
            "Click"
          )
        }}
        id={"recycling"}
      >
        <StHeadlineImage
          fixed={data.recyclingIcon.childImageSharp.fixed}
          alt={"Recycling"}
          title={"Recycling"}
        />
        <StHeadlineText>RECYCLING</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={recyclingOpen}>
        {getJSXforCompaniesOfSubject(recyclingCompanies)}
      </SubjectContentContainer>

      <StHeadlineContainer
        isOpen={temperatureTechniquesOpen}
        onClick={event => {
          toggleOpenSubjects(
            setTemperatureTechniquesOpen,
            temperatureTechniquesOpen
          )
          sendEtrackerSubjectClickEvent(
            event,
            "Themenbereich",
            "WÄRME- UND KÄLTETECHNIK",
            "Click"
          )
        }}
        id={"waermekaelte"}
      >
        <StHeadlineImage
          fixed={data.waermeUndKaelteIcon.childImageSharp.fixed}
          alt={"Wärme und Kältetechnik"}
          title={"Wärme und Kältetechnik"}
        />
        <StHeadlineText>WÄRME- UND KÄLTETECHNIK</StHeadlineText>
      </StHeadlineContainer>
      <SubjectContentContainer isOpen={temperatureTechniquesOpen}>
        {getJSXforCompaniesOfSubject(temperatureTechniquesCompanies)}
      </SubjectContentContainer>
    </StSubjectListContainer>
  )
}
