import React from "react"
import ihkLogo from "../assets/images/logo/IHK-Da-logo4c-freigestellt.png"
import styled from "styled-components"
import { layoutStyles } from "../styles/layoutStyles"
import { colors } from "../styles/colors"

import iwik_logo from "../assets/newLogos/logos/200529_IWIK Logo-02.jpg"
import entega_logo from "../assets/newLogos/logos/entega.png"
import etaSolutions_logo from "../assets/newLogos/logos/ETA-Solutions-Logo-RGB.png"
import jfGroup_logo from "../assets/newLogos/logos/JF_Group_grau.png"
import ptwLogo_logo from "../assets/newLogos/logos/PTW_Logo_RGB_185_15_34 (002).jpg"

import dieburg_logo from "../assets/newLogos/logos/LaDaDi_Logo_mC_unten_RGB_L_fin.jpg"
import cityDarmstadt_logo from "../assets/newLogos/logos/A4_Darmstadt-Logo_rgb-3c.jpg"
import bergstrasse_logo from "../assets/newLogos/logos/stvaportal_kreis-bergstrasse_de-layout_set_logo.png"
import odenwald_logo from "../assets/newLogos/logos/odenwald.png"
import grossGerau_logo from "../assets/newLogos/logos/Logo_Kreis_GG_ohne_Hintergrund.jpg"

const LogoImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${layoutStyles.contentWidth};
  padding: ${layoutStyles.contentPadding};
`

const StImageLogo = styled.img`
  max-width: 350px;
  height: auto;
  margin-top: 1.5rem;

  @media only screen and (max-width: 650px) {
    padding-top: 0.5rem;
  }

  @media only screen and (max-width: 399px) {
    max-width: 250px;
  }
`

const NetworkPartners = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`
const NetworkPartnerLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const StPartnerImage = styled.img`
  max-width: 125px;
  height: auto;
  margin: 1rem;
`

const StHeadline = styled.h2`
  color: ${colors.ihk_darkblue};
  margin-bottom: -0.25rem;
`

const sendEtrackerImageClickEvent = (event, imageName) => {
  if (
    typeof window !== "undefined" &&
    window &&
    window._etracker &&
    window._etracker.sendEvent &&
    window.et_ClickEvent
  ) {
    const _etracker = window._etracker
    _etracker.sendEvent(new window.et_ClickEvent(imageName, "Logos", "Bild"))
  }
}

export const PartnerLogos = () => {
  return (
    <LogoImageContainer>
      <a
        onClick={event =>
          sendEtrackerImageClickEvent(event, "IHK-Darmstadt-Logo")
        }
        href={"https://www.darmstadt.ihk.de/"}
        target={"_blank"}
        rel="noreferrer"
      >
        <StImageLogo src={ihkLogo} alt={"IHK Darmstadt Rhein Main Neckar"} />
      </a>
      <NetworkPartners>
        <NetworkPartnerLogos>
          <a
            onClick={event =>
              sendEtrackerImageClickEvent(event, "IHK-Dieburg-Logo")
            }
            href={"https://www.ladadi.de/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage
              src={dieburg_logo}
              alt={"Landkreis Darmstadt Dieburg"}
              style={{
                maxWidth: "150px",
              }}
            />
          </a>
          <a
            onClick={event =>
              sendEtrackerImageClickEvent(event, "Odenwald-Logo")
            }
            href={"https://www.odenwaldkreis.de/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage
              src={odenwald_logo}
              alt={"Odenwaldkreis"}
              style={{
                maxWidth: "150px",
              }}
            />
          </a>
          <a
            onClick={event =>
              sendEtrackerImageClickEvent(event, "Bergstraße-Logo")
            }
            href={"https://www.kreis-bergstrasse.de/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage
              src={bergstrasse_logo}
              alt={"Kreis Bergstraße"}
              style={{
                paddingTop: "10px",
                maxWidth: "160px",
              }}
            />
          </a>
          <a
            onClick={event =>
              sendEtrackerImageClickEvent(event, "Groß-Gerau-Logo")
            }
            href={"https://www.kreisgg.de/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage
              src={grossGerau_logo}
              alt={"Groß-Gerau"}
              style={{
                maxWidth: "100px",
              }}
            />
          </a>
          <a
            onClick={event =>
              sendEtrackerImageClickEvent(event, "Stadt-Darmstadt-Logo")
            }
            href={"https://www.darmstadt.de/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage
              src={cityDarmstadt_logo}
              alt={"Stadt-Darmstadt"}
              style={{
                maxWidth: "150px",
              }}
            />
          </a>
        </NetworkPartnerLogos>
      </NetworkPartners>
      <NetworkPartners>
        <NetworkPartnerLogos>
          <a
            onClick={event =>
              sendEtrackerImageClickEvent(event, "IWIK_GmbH-Logo")
            }
            href={"https://www.iwik.de/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage src={iwik_logo} alt={"IWIK_GmbH"} />
          </a>
          <a
            onClick={event => sendEtrackerImageClickEvent(event, "Entega-Logo")}
            href={"https://www.entega.de/geschaeftskunden/ "}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage
              src={entega_logo}
              alt={"entega"}
              style={{
                maxWidth: "80px",
              }}
            />
          </a>
          <a
            onClick={event =>
              sendEtrackerImageClickEvent(event, "eta-solutions-Logo")
            }
            href={"https://eta-solutions.de/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage
              src={etaSolutions_logo}
              alt={"IWIK_GmbH"}
              style={{
                maxWidth: "150px",
              }}
            />
          </a>
          <a
            onClick={event =>
              sendEtrackerImageClickEvent(event, "jf-group-Logo")
            }
            href={"https://jf-group.net/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage
              src={jfGroup_logo}
              alt={"jf-group"}
              style={{
                maxWidth: "150px",
              }}
            />
          </a>
          <a
            onClick={event => sendEtrackerImageClickEvent(event, "ptwLogo")}
            href={"https://eta-fabrik.de/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <StPartnerImage
              src={ptwLogo_logo}
              alt={"ptw"}
              style={{
                maxWidth: "100px",
              }}
            />
          </a>
        </NetworkPartnerLogos>
      </NetworkPartners>
    </LogoImageContainer>
  )
}
