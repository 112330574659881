import React from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import PropTypes from "prop-types"
import MarkdownIt from "markdown-it"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  justify-content: space-around;
`

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid ${colors.ihk_darkgrey};
  flex-wrap: wrap;
  width: 350px;
  margin-bottom: 0.25rem;

  @media only screen and (max-width: 499px) {
    width: 300px;
  }
  @media only screen and (max-width: 349px) {
    width: 280px;
  }
  @media only screen and (max-width: 310px) {
    width: 260px;
  }
`

const StHeadline = styled.span`
  font-weight: bold;
`

const StInfo = styled.span`
  color: ${colors.ihk_darkgrey};
`

const StHeadlineImage = styled.img`
  max-width: 150px;
  height: auto;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-start;
`

const CompanyInformationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3.5rem;
  @media only screen and (max-width: 799px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const LinkWrapper = styled.a`
  text-decoration: none;
  color: ${colors.ihk_darkgrey};
  :hover {
    text-decoration: underline;
  }
`
const LinkWrapperImage = styled(LinkWrapper)`
  align-self: flex-start;
`

const StMarkdownDiv = styled.div`
  color: ${colors.ihk_darkgrey};
  a {
    color: ${colors.ihk_darkgrey};
    :hover {
      text-decoration: underline;
    }
  }
`

export const CompanyCard = ({ company }) => {
  const formatNumberToString = number => {
    if (number && typeof number === "number") {
      return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    }
  }

  const LogoContainer =
    company && company.homepageCompanyLink ? (
      <LinkWrapperImage
        href={`//${company.homepageCompanyLink}`}
        target={"_blank"}
      >
        {company && company.companyLogo && company.companyLogo.publicURL ? (
          <StHeadlineImage
            src={company.companyLogo.publicURL}
            alt={company.company}
          />
        ) : null}
      </LinkWrapperImage>
    ) : company && company.companyLogo && company.companyLogo.publicURL ? (
      <StHeadlineImage
        src={company.companyLogo.publicURL}
        alt={company.company}
      />
    ) : null

  const Unternehmen = () => {
    return company && company.company ? (
      <InformationContainer>
        <StHeadline>Unternehmen</StHeadline>
        <StInfo>
          {company && company.homepageCompanyLink ? (
            <LinkWrapper
              href={`//${company.homepageCompanyLink}`}
              target={"_blank"}
            >
              {company.company}
            </LinkWrapper>
          ) : (
            company.company
          )}
        </StInfo>
      </InformationContainer>
    ) : null
  }

  const Foerderzuschuss = () => {
    return company && company.fundingAmount ? (
      <InformationContainer>
        <StHeadline>Förderzuschuss</StHeadline>
        {company && company.fundingAmount ? (
          <StInfo>{formatNumberToString(company.fundingAmount)} Euro</StInfo>
        ) : null}
      </InformationContainer>
    ) : null
  }

  const Amortisationszeit = () => {
    return company && company.paybackPeriod ? (
      <InformationContainer>
        <StHeadline>Amortisationszeit</StHeadline>
        {company && company.paybackPeriod ? (
          <StInfo>{company.paybackPeriod} Jahre</StInfo>
        ) : null}
      </InformationContainer>
    ) : null
  }

  const Masnahme = () => {
    return company && company.measure ? (
      <InformationContainer>
        <StHeadline>Klima-&nbsp;und&nbsp;Energiesparmaßnahme</StHeadline>
        <StInfo>{company.measure}</StInfo>
      </InformationContainer>
    ) : null
  }

  const Energieeinsparung = () => {
    return company && company.energySavingPerYear ? (
      <InformationContainer>
        <StHeadline>Energieeinsparung pro Jahr</StHeadline>
        {company && company.energySavingPerYear ? (
          <StInfo>
            {formatNumberToString(company.energySavingPerYear)} kWh
          </StInfo>
        ) : null}
      </InformationContainer>
    ) : null
  }

  const Investitionssumme = () => {
    return company && company.investmentAmount ? (
      <InformationContainer>
        <StHeadline>Investitionssumme</StHeadline>
        {company && company.investmentAmount ? (
          <StInfo>{formatNumberToString(company.investmentAmount)} Euro</StInfo>
        ) : null}
      </InformationContainer>
    ) : null
  }

  const coSaving = () => {
    return company && company.coSaving ? (
      <InformationContainer>
        <StHeadline>
          C0<sub style={{ fontSize: "0.75rem" }}>2</sub> Einsparung
        </StHeadline>
        {company && company.coSaving ? (
          <StInfo>{formatNumberToString(company.coSaving)} t</StInfo>
        ) : null}
      </InformationContainer>
    ) : null
  }

  const Foerderprogramm = () => {
    return company && company.homepageFundingprogram ? (
      <InformationContainer>
        <StHeadline>Förderprogramm</StHeadline>
        <StInfo>
          {company && company.homepageFundingprogram ? (
            <LinkWrapper
              href={`//${company.homepageFundingprogram}`}
              target={"_blank"}
            >
              {company.fundingProgram}
            </LinkWrapper>
          ) : (
            company.fundingProgram
          )}
        </StInfo>
      </InformationContainer>
    ) : null
  }

  const ProjektPartner = () => {
    return company && company.homepageConsultationAndPartner ? (
      <InformationContainer>
        <StHeadline>Projektpartner</StHeadline>
        <StInfo>
          {company && company.homepageConsultationAndPartner ? (
            <LinkWrapper
              href={`//${company.homepageConsultationAndPartner}`}
              target={"_blank"}
            >
              {company.consultationAndPartner}
            </LinkWrapper>
          ) : (
            company.consultationAndPartner
          )}
        </StInfo>
      </InformationContainer>
    ) : null
  }

  const WeitereEnergieberaterUndProjektpartner = () => {
    const md = new MarkdownIt()

    return company && company.moreEnergyAndProjectPartners ? (
      <InformationContainer>
        <StHeadline>Weitere Projektpartner</StHeadline>
        <StInfo>
          <StMarkdownDiv
            dangerouslySetInnerHTML={{
              __html: md.renderInline(company.moreEnergyAndProjectPartners),
            }}
          />
        </StInfo>
      </InformationContainer>
    ) : null
  }

  return (
    <Container>
      {LogoContainer}
      <CompanyInformationContainer>
        {Unternehmen()}
        {Foerderzuschuss()}
        {Amortisationszeit()}
        {Masnahme()}
        {Energieeinsparung()}
        {Investitionssumme()}
        {coSaving()}
        {Foerderprogramm()}
        {ProjektPartner()}
        {WeitereEnergieberaterUndProjektpartner()}
      </CompanyInformationContainer>
    </Container>
  )
}

CompanyCard.propTypes = {
  company: PropTypes.shape({
    title: PropTypes.string.isRequired,
    companyLogo: PropTypes.shape({
      id: PropTypes.string.isRequired,
      publicURL: PropTypes.string.isRequired,
    }),
    coSaving: PropTypes.number,
    company: PropTypes.string.isRequired,
    consultationAndPartner: PropTypes.string,
    energySavingPerYear: PropTypes.number,
    fundingAmount: PropTypes.number,
    fundingProgram: PropTypes.string,
    investmentAmount: PropTypes.number,
    measure: PropTypes.string,
    paybackPeriod: PropTypes.number,
    subjects: PropTypes.string,
    homepageCompanyLink: PropTypes.string,
    homepageConsultationAndPartner: PropTypes.string,
    homepageFundingprogram: PropTypes.string,
    moreEnergyAndProjectPartners: PropTypes.string,
  }),
}
