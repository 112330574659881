import React from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import { layoutStyles } from "../styles/layoutStyles"

const Container = styled.div`
  padding: 2rem 0 2rem;
  background-color: ${colors.ihk_darkblue};
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${layoutStyles.contentWidth};
  padding: 0 1.0875rem 0;
  margin: 0 auto;
`

const Headline = styled.h1`
  font-weight: 200;
  color: white;
  font-size: 3rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Asap Condensed", sans-serif;
`

export const BestPractice = () => {
  return (
    <Container id={"bestpractice"}>
      <InnerContainer>
        <Headline>Best-Practices aus Südhessen</Headline>
      </InnerContainer>
    </Container>
  )
}
