import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import { layoutStyles } from "../styles/layoutStyles"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { SubjectCard } from "./SubjectCard"
import { navigate } from "gatsby"
const ImageWrapper = styled.div`
  //background-color: ${colors.ihk_lightgrey};
`

const CopyrightText = styled.span`
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  color: ${colors.ihk_darkgrey};
  font-size: 0.8rem;

  @media only screen and (max-width: 499px) {
    font-size: 0.6rem;
  }
`

const InnerImageWrapper = styled.div`
  position: relative;
  max-width: ${layoutStyles.contentWidth};
  margin: 0 auto;
  padding: 2rem 0;
`
const StFactoryImage = styled(Img)`
  max-width: 100%;
  height: auto;
`
const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  max-width: ${layoutStyles.contentWidth};
  padding: ${layoutStyles.contentPadding};
  font-family: "Asap Condensed", sans-serif;
`
const Headline = styled.h1`
  margin: 0;
  font-weight: 200;
  width: fit-content;
  color: ${colors.ihk_darkblue};
  padding-bottom: 0.5rem;
  font-size: 3rem;

  @media only screen and (max-width: 499px) {
    font-size: 1.5rem;
  }
`
const SubHeadline = styled.span`
  margin-top: 0.5rem;
  font-size: 1.3rem;
  color: ${colors.ihk_darkblue};
  text-transform: uppercase;
  @media only screen and (max-width: 499px) {
    font-size: 0.9rem;
  }
`

const FactoryImageButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 75px;
  height: auto;

  @media only screen and (max-width: 850px) {
    width: 50px;
  }
  @media only screen and (max-width: 499px) {
    width: 35px;
  }
`
const BeleuchtungButton = styled(FactoryImageButton)`
  top: 30%;
  right: 30%;
`

const BeleuchtungHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 20%;
  right: 38%;
`

const DruckluftButton = styled(FactoryImageButton)`
  top: 60%;
  left: 17%;
`

const DruckluftHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 50%;
  left: 25%;
`

const EnergiemanagementButton = styled(FactoryImageButton)`
  top: 80%;
  left: 45%;
`

const EnergiemanagementHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 65%;
  left: 53%;
`

const EnergieversorgungButton = styled(FactoryImageButton)`
  top: 5%;
  left: 60%;
`

const EnergieversorgungHoverCard = styled(SubjectCard)`
  position: absolute;
  top: -5%;
  left: 68%;
`

const GebaeudeBaumaterialButton = styled(FactoryImageButton)`
  top: 26%;
  left: 80%;
`

const MaterialHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 15%;
  left: 54%;
`

const MobilitaetButton = styled(FactoryImageButton)`
  top: 48%;
  left: 88%;
`

const MobilitaetHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 35%;
  right: 13%;
`

const BiodiversitaetButton = styled(FactoryImageButton)`
  top: 10%;
  left: 37%;
`

const BiodiversitaetHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 0%;
  left: 45%;
`

const MaschinenoptimierungButton = styled(FactoryImageButton)`
  top: 43%;
  left: 45%;
`

const MaschinenoptimierungHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 32%;
  left: 53%;
`

const MotivationButton = styled(FactoryImageButton)`
  top: 30%;
  left: 17%;
`

const MotivationHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 20%;
  left: 25%;
`

const RecyclingButton = styled(FactoryImageButton)`
  top: 63%;
  left: 65%;
`

const RecyclingHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 50%;
  left: 39%;
`

const WaermeKaeltetechnikButton = styled(FactoryImageButton)`
  top: 76%;
  left: 24%;
`

const WaermeKaelteHoverCard = styled(SubjectCard)`
  position: absolute;
  top: 65%;
  left: 32%;
`

const FactoryContainer = styled.div`
  overflow: hidden;
`

export const Factory = ({
  lightningOpen,
  biodiversityOpen,
  airpreasureOpen,
  energyManagementOpen,
  energySupplyOpen,
  buildingMaterialOpen,
  machineOptimizingOpen,
  motivationOpen,
  mobilityOpen,
  recyclingOpen,
  temperatureTechniquesOpen,
  toggleOpenSubjects,
  setLightningOpen,
  setBiodiversityOpen,
  setAirPreasureOpen,
  setEnergyManagementOpen,
  setEnergySupplyOpen,
  setBuildingMaterialOpen,
  setMachineOptimizingOpen,
  setMotivationOpen,
  setMobilityOpen,
  setRecyclingOpen,
  setTemperatureTechniquesOpen,
}) => {
  const [hoverLightning, setHoverLightning] = useState(false)
  const [hoverDruckluft, setHoverDruckluft] = useState(false)
  const [hoverEnergiemanagement, setHoverEnergiemanagement] = useState(false)
  const [hoverEnergieversorgung, setHoverEnergieversorgung] = useState(false)
  const [hoverMaterial, setHoverMaterial] = useState(false)
  const [hoverMaschinenOptimierung, setHoverMaschinenOptimierung] = useState(
    false
  )
  const [hoverMotivation, setHoverMotivation] = useState(false)
  const [hoverMobilitaet, setHoverMobilitaet] = useState(false)
  const [hoverRecycling, setHoverRecycling] = useState(false)
  const [hoverWaermeKaelte, setHoverWaermeKaelte] = useState(false)
  const [hoverBiodiversitaet, setHoverBiodiversitaet] = useState(false)

  const data = useStaticQuery(graphql`
    {
      factory: file(
        relativePath: { eq: "Energieeffiziente-Fabrik-20201001-Final-4K.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      beleuchtung: file(
        relativePath: { eq: "IHK_Beleuchtung_Kachel_gruen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      biodiversitaet: file(
        relativePath: { eq: "IHK_Biodiversitaet_Kachel_gruen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      druckluft: file(relativePath: { eq: "IHK_Druckluft_Kacheln_gruen.png" }) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      energiemanagement: file(
        relativePath: { eq: "IHK_Energiemanagement_Kachel_gruen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      energieversorgung: file(
        relativePath: { eq: "IHK_Energieversorgung_Kachel_gruen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      gebaeudeBaumaterial: file(
        relativePath: { eq: "IHK_GebaeudeBaumaterial_Kachel_gruen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      maschinenOptimierung: file(
        relativePath: { eq: "IHK_Maschinenoptimierung_Kachel_gruen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      motivation: file(
        relativePath: { eq: "IHK_Motivation_Kachel_gruen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mobilitaet: file(
        relativePath: { eq: "IHK_Mobilitaet_Kachel_gruen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      recycling: file(relativePath: { eq: "IHK_Recycling_Kachel_gruen.png" }) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      waermeUndKaelte: file(
        relativePath: { eq: "IHK_WaermeKaeltetechnik_Kachel_gruen.png" }
      ) {
        id
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      beleuchtungIcon: file(
        relativePath: { eq: "IHK_Beleuchtung_Icon_weiß.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      biodiversitaetIcon: file(
        relativePath: { eq: "IHK_Biodiversität_Icon_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      druckluftIcon: file(
        relativePath: { eq: "IHK_Druckluft_Icon_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      energiemanagementIcon: file(
        relativePath: { eq: "IHK_Energiemanagement_Icon_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      energieversorgungIcon: file(
        relativePath: { eq: "IHK_Energieversorgung_Icon_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gebaeudeBaumaterialIcon: file(
        relativePath: { eq: "IHK_GebaeudeBaumaterial_Icon_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maschinenOptimierungIcon: file(
        relativePath: { eq: "IHK_Maschinenoptimierung_Icon_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      motivationIcon: file(
        relativePath: { eq: "IHK_Motivation_Icon_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobilitaetIcon: file(
        relativePath: { eq: "IHK_Mobilitaet_Icon_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recyclingIcon: file(
        relativePath: { eq: "IHK_Recycling_Icon_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      waermeUndKaelteIcon: file(
        relativePath: { eq: "IHK_WaermeKaeltetechnik_Icons_weiss.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const transitionNavigationDelay = 300

  return (
    <FactoryContainer>
      <HeadlineContainer>
        <SubHeadline>
          Der Quickfinder zu Ihrer Klima- und Energieeffizienzlösung
        </SubHeadline>
        <Headline>Lernen von guten Beispielen aus der Region</Headline>
      </HeadlineContainer>
      <ImageWrapper>
        <InnerImageWrapper>
          <StFactoryImage
            fluid={data.factory.childImageSharp.fluid}
            alt={"Fabrikgrafik mit allen Themenbereichen"}
            title={"Fabrikgrafik"}
          />
          <div
            onMouseEnter={() => setHoverLightning(true)}
            onMouseLeave={() => setHoverLightning(false)}
            onClick={() => {
              toggleOpenSubjects(setLightningOpen, lightningOpen)
              setTimeout(
                () => navigate("/#beleuchtung", { replace: true }),
                transitionNavigationDelay
              )
            }}
          >
            <BeleuchtungButton>
              <Img
                fluid={data.beleuchtungIcon.childImageSharp.fluid}
                alt={"Beleuchtung"}
                title={"Beleuchtung"}
              />
            </BeleuchtungButton>
          </div>
          <BeleuchtungHoverCard
            title={"BELEUCHTUNG"}
            bulletpoints={["LED-Beleuchtung", "Lichtsteuerung", "Contracting"]}
            isHovered={hoverLightning}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(setAirPreasureOpen, airpreasureOpen)
              setTimeout(
                () => navigate("/#druckluft", { replace: true }),
                transitionNavigationDelay
              )
            }}
            onMouseEnter={() => setHoverDruckluft(true)}
            onMouseLeave={() => setHoverDruckluft(false)}
          >
            <DruckluftButton>
              <Img
                fluid={data.druckluftIcon.childImageSharp.fluid}
                alt={"Druckluft"}
                title={"Druckluft"}
              />
            </DruckluftButton>
          </div>
          <DruckluftHoverCard
            title={"DRUCKLUFT"}
            bulletpoints={[
              "Drehzahlregulierung",
              "Leckagen eliminieren",
              "Abschaltautomatisierung",
            ]}
            isHovered={hoverDruckluft}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(setEnergyManagementOpen, energyManagementOpen)
              setTimeout(
                () => navigate("/#energiemanagement", { replace: true }),
                transitionNavigationDelay
              )
            }}
            onMouseEnter={() => setHoverEnergiemanagement(true)}
            onMouseLeave={() => setHoverEnergiemanagement(false)}
          >
            <EnergiemanagementButton>
              <Img
                fluid={data.energiemanagementIcon.childImageSharp.fluid}
                alt={"Energiemanagement"}
                title={"Energiemanagement"}
              />
            </EnergiemanagementButton>
          </div>
          <EnergiemanagementHoverCard
            title={"ENERGIE-\nMANAGEMENT"}
            bulletpoints={[
              "Auditing",
              "Lastmanagement",
              "Nachfrageflexibilität",
            ]}
            isHovered={hoverEnergiemanagement}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(setEnergySupplyOpen, energySupplyOpen)
              setTimeout(
                () => navigate("/#energieversorgung", { replace: true }),
                transitionNavigationDelay
              )
            }}
            onMouseEnter={() => setHoverEnergieversorgung(true)}
            onMouseLeave={() => setHoverEnergieversorgung(false)}
          >
            <EnergieversorgungButton>
              <Img
                fluid={data.energieversorgungIcon.childImageSharp.fluid}
                alt={"Energieversorgung"}
                title={"Energieversorgung"}
              />
            </EnergieversorgungButton>
          </div>
          <EnergieversorgungHoverCard
            title={"ENERGIE-\nVERSORGUNG"}
            bulletpoints={[
              "Eigenversorgung",
              "Energiespeicher",
              "Einkaufsgemeinschaft",
            ]}
            isHovered={hoverEnergieversorgung}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(setBuildingMaterialOpen, buildingMaterialOpen)
              setTimeout(() => navigate("/#material", { replace: true }), 100)
            }}
            onMouseEnter={() => setHoverMaterial(true)}
            onMouseLeave={() => setHoverMaterial(false)}
          >
            <GebaeudeBaumaterialButton>
              <Img
                fluid={data.gebaeudeBaumaterialIcon.childImageSharp.fluid}
                alt={"Gebäude & Baumaterial"}
                title={"Gebäude & Baumaterial"}
              />
            </GebaeudeBaumaterialButton>
          </div>
          <MaterialHoverCard
            title={"GEBÄUDE &-\nBAUMATERIAL"}
            bulletpoints={["Dämmung", "Verschattung", "Heizungssteuerung"]}
            isHovered={hoverMaterial}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(setBiodiversityOpen, biodiversityOpen)
              setTimeout(
                () => navigate("/#biodiversitaet", { replace: true }),
                transitionNavigationDelay
              )
            }}
            onMouseEnter={() => setHoverBiodiversitaet(true)}
            onMouseLeave={() => setHoverBiodiversitaet(false)}
          >
            <BiodiversitaetButton>
              <Img
                fluid={data.biodiversitaetIcon.childImageSharp.fluid}
                alt={"Biodiversität"}
                title={"Biodiversität"}
              />
            </BiodiversitaetButton>
          </div>
          <BiodiversitaetHoverCard
            title={"BIODIVERSITÄT"}
            bulletpoints={["Bienenvölker", "Dachbegrünung", "Blühstreifen"]}
            isHovered={hoverBiodiversitaet}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(
                setMachineOptimizingOpen,
                machineOptimizingOpen
              )
              setTimeout(
                () => navigate("/#maschinenoptimierung", { replace: true }),
                transitionNavigationDelay
              )
            }}
            onMouseEnter={() => setHoverMaschinenOptimierung(true)}
            onMouseLeave={() => setHoverMaschinenOptimierung(false)}
          >
            <MaschinenoptimierungButton>
              <Img
                fluid={data.maschinenOptimierungIcon.childImageSharp.fluid}
                alt={"Maschinenoptimierung"}
                title={"Maschinenoptimierung"}
              />
            </MaschinenoptimierungButton>
          </div>
          <MaschinenoptimierungHoverCard
            title={"MASCHINEN-\nOPTIMIERUNG"}
            bulletpoints={[
              "Isolierung",
              "Standby-Management",
              "Antriebsmotoren",
            ]}
            isHovered={hoverMaschinenOptimierung}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(setMobilityOpen, mobilityOpen)
              setTimeout(
                () => navigate("/#mobilitaet", { replace: true }),
                transitionNavigationDelay
              )
            }}
            onMouseEnter={() => setHoverMobilitaet(true)}
            onMouseLeave={() => setHoverMobilitaet(false)}
          >
            <MobilitaetButton>
              <Img
                fluid={data.mobilitaetIcon.childImageSharp.fluid}
                alt={"Mobilität"}
                title={"Mobilität"}
              />
            </MobilitaetButton>
          </div>
          <MobilitaetHoverCard
            title={"MOBILITÄT"}
            bulletpoints={[
              "e-Ladestationen",
              "Wasserstofffahrzeuge",
              "Mobilitätsmanagement",
            ]}
            isHovered={hoverMobilitaet}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(setMotivationOpen, motivationOpen)
              setTimeout(
                () => navigate("/#motivation", { replace: true }),
                transitionNavigationDelay
              )
            }}
            onMouseEnter={() => setHoverMotivation(true)}
            onMouseLeave={() => setHoverMotivation(false)}
          >
            <MotivationButton>
              <Img
                fluid={data.motivationIcon.childImageSharp.fluid}
                alt={"Motivation"}
                title={"Motivation"}
              />
            </MotivationButton>
          </div>
          <MotivationHoverCard
            title={"MOTIVATION"}
            bulletpoints={[
              "Mitarbeiterschulung",
              "Azubi Training",
              "ETA-Lernfabrik",
            ]}
            isHovered={hoverMotivation}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(setRecyclingOpen, recyclingOpen)
              setTimeout(
                () => navigate("/#recycling", { replace: true }),
                transitionNavigationDelay
              )
            }}
            onMouseEnter={() => setHoverRecycling(true)}
            onMouseLeave={() => setHoverRecycling(false)}
          >
            <RecyclingButton>
              <Img
                fluid={data.recyclingIcon.childImageSharp.fluid}
                alt={"Recycling"}
                title={"Recycling"}
              />
            </RecyclingButton>
          </div>
          <RecyclingHoverCard
            title={"RECYCLING"}
            bulletpoints={[
              "Einsatz von Rezyklaten",
              "Verpackungsoptimierung",
              "Styroporpresse",
            ]}
            isHovered={hoverRecycling}
          />
          <div
            onClick={() => {
              toggleOpenSubjects(
                setTemperatureTechniquesOpen,
                temperatureTechniquesOpen
              )
              setTimeout(
                () => navigate("/#waermekaelte", { replace: true }),
                transitionNavigationDelay
              )
            }}
            onMouseEnter={() => setHoverWaermeKaelte(true)}
            onMouseLeave={() => setHoverWaermeKaelte(false)}
          >
            <WaermeKaeltetechnikButton>
              <Img
                fluid={data.waermeUndKaelteIcon.childImageSharp.fluid}
                alt={"Wärme und Kältetechnik"}
                title={"Wärme und Kältetechnik"}
              />
            </WaermeKaeltetechnikButton>
          </div>
          <WaermeKaelteHoverCard
            title={"WÄRME- UND\nKÄLTETECHNIK"}
            bulletpoints={[
              "Einsatz von Freikühlern",
              "Thermische Vernetzung",
              "Heizungssteuerung",
            ]}
            isHovered={hoverWaermeKaelte}
          />
          <CopyrightText>© IHK Darmstadt Rhein Main Neckar</CopyrightText>
        </InnerImageWrapper>
      </ImageWrapper>
    </FactoryContainer>
  )
}
